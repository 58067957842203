import React from 'react';
import loadable from '@loadable/component';
import { bool, func } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faStars } from '@fortawesome/pro-solid-svg-icons';

import useStyles from './styles';

const GoldBenefits = loadable(() => import('components/subscription-benefits/gold'));
const BaseSub = loadable(() => import('components/landing-prices/base-sub'));
const Divider = loadable(() => import('@material-ui/core/Divider'));

const model = {
  cost: '£9:99',
  name: 'Gold',
};

const GoldSubscription = ({
  looking = false,
  onExpand = () => {},
  expanded = false,
  single = false,
  landing = false,
}) => {
  const styles = useStyles();

  const { name, cost } = model;

  return (
    <>
    <span id={name}/>
    <BaseSub
      name={name}
      single={single}
      level="3"
      title={`${name} ${cost} month`}
      smTitle={`${name} ${cost}p/m`}
      subheader="10,000 active cards"
      smSubTitle="10,000 active cards"
      avatar={
        <FontAwesomeIcon icon={faStars} className={styles.goldAvatar}/>
        }
      looking={looking}
      onExpand={() => {
        setTimeout(async () => {
          const buildDataLayer = await import('data-layer');
          const dataLayer = buildDataLayer.default();
          dataLayer.trackLanding({
            descriptor: 'toggle_subcription_information',
            label: name,
          });
          onExpand();
        }, 100);
      }}
      expanded={expanded}
      content={
        <>
          <div className={styles.dividerMargin}>
            <Divider />
          </div>
          <GoldBenefits/>
        </>
      }
      landing={landing}
    />
    </>
  );
};
GoldSubscription.propTypes = {
  looking: bool,
  onExpand: func,
  expanded: bool,
  single: bool,
  landing: bool,
};

export default GoldSubscription;
